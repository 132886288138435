import React from "react";

const zeusPath = "https://www.washingtonpost.com/zeus/main.js";
const googlePublisherTagPath =
  "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

export function loadZeusSass() {
  return [
    <script
      src={googlePublisherTagPath}
      async
      key="google-publisher-tag"
      data-testid="google-publisher-tag"
    />,
    <script src={zeusPath} async key="zeus-sass" data-testid="zeus-sass" />
  ];
}

export default function ZeusSaas() {
  return <React.Fragment>{loadZeusSass()}</React.Fragment>;
}
