/**
 * CollectionPage SEO Schema
 * @param {string} url
 * @param {string} title
 * @param {string} description
 * @returns {Object}
 */
export const collectionPageSchema = (url, title, description) => {
  const pageUrl = `https://www.washingtonpost.com${url}`;

  return {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    name: title,
    description,
    url: pageUrl,
    mainEntityOfPage: pageUrl,
    image: [
      "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/RUGLRTKULZB6XPESJMXXJVLTDI.jpeg&w=1440"
    ],
    isAccessibleForFree: true
  };
};
