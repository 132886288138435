/* eslint-disable no-underscore-dangle */

/**
 * Load third party scripts for vendors GA + GTM
 */
import React from "react";
import PropTypes from "prop-types";

import {
  loadCatchpointRUM,
  loadCatchpointTagging
} from "@washingtonpost/site-third-party-scripts";
import getEnv from "../../utilities/env";

// TODO: Extract property ID below into a site prop
const loadDataLayer = () => {
  return {
    __html: `window.dataLayer = window.dataLayer || [];`
  };
};

const loadGTMScript = () => {
  return {
    __html: `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WHNNX8B');`
  };
};

const ThirdPartyScripts = () => {
  const env = getEnv() === "prod" ? "prod" : "testing";

  return (
    <React.Fragment>
      <script dangerouslySetInnerHTML={loadDataLayer()} />
      <script dangerouslySetInnerHTML={loadGTMScript()} />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={loadCatchpointRUM({ env })}
      />
      <script
        language="javascript"
        type="text/javascript"
        dangerouslySetInnerHTML={loadCatchpointTagging({ tag: "homepage" })}
      />
      <script
        defer
        type="text/javascript"
        src="https://www.washingtonpost.com/wp-stat/assets/third-party-scripts/onelink-smart-script-latest.min.js"
      />
    </React.Fragment>
  );
};

ThirdPartyScripts.propTypes = {
  contentType: PropTypes.string
};

export default ThirdPartyScripts;

export const loadThirdPartyScripts = ({ contentType }) => {
  const env = getEnv() === "prod" ? "prod" : "testing";
  const scriptKeyPrefix = `${contentType}-script`;

  return [
    <script
      dangerouslySetInnerHTML={loadDataLayer()}
      key={`${scriptKeyPrefix}-datalayer`}
      data-testid={`${scriptKeyPrefix}-datalayer`}
    />,
    <script
      dangerouslySetInnerHTML={loadGTMScript()}
      key={`${scriptKeyPrefix}-gtm`}
      data-testid={`${scriptKeyPrefix}-gtm`}
    />,
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={loadCatchpointRUM({ env })}
      key={`${scriptKeyPrefix}-catchpoint-rum`}
      data-testid={`${scriptKeyPrefix}-catchpoint-rum`}
    />,
    <script
      language="javascript"
      type="text/javascript"
      dangerouslySetInnerHTML={loadCatchpointTagging({ tag: "homepage" })}
      key={`${scriptKeyPrefix}-catchpoint-tagging`}
      data-testid={`${scriptKeyPrefix}-catchpoint-tagging`}
    />,
    <script
      defer
      type="text/javascript"
      src="https://www.washingtonpost.com/wp-stat/assets/third-party-scripts/onelink-smart-script-latest.min.js"
      key={`${scriptKeyPrefix}-onelink`}
      data-testid={`${scriptKeyPrefix}-onelink`}
    />
  ];
};
