import React from "react";
import PropTypes from "prop-types";

export const domains = [
  "adserver-us.adtech.advertising.com",
  "secure.adnxs.com",
  "bidder.criteo.com",
  "static.criteo.net",
  "cdn.krxd.net",
  "widgets.outbrain.com",
  "images.outbrain.com",
  "log.outbrain.com",
  "amplifypixel.outbrain.com",
  "odb.outbrain.com",
  "js-sec.indexww.com",
  "as-sec.casalemedia.com",
  "as.casalemedia.com",
  "sofia.trustx.org",
  "c.amazon-adsystem.com",
  "s.amazon-adsystem.com",
  "aax.amazon-adsystem.com",
  "t.teads.tv",
  "beacon.krxd.net",
  "unpkg.com",
  "r.3gl.net"
];

const criticalDomains = [
  "www.google-analytics.com",
  "www.googletagmanager.com",
  "adservice.google.com"
];

const Preconnect = () => {
  return (
    <React.Fragment>
      {domains.map((domain) => (
        <link key={domain} rel="dns-prefetch" href={`https://${domain}`} />
      ))}
      {criticalDomains.map((domain) => (
        <link key={domain} rel="preconnect" href={`https://${domain}`} />
      ))}
    </React.Fragment>
  );
};
Preconnect.propTypes = {
  globalContent: PropTypes.object
};
export default Preconnect;

export const preconnectDomains = [
  domains.map((domain) => (
    <link key={domain} rel="dns-prefetch" href={`https://${domain}`} />
  )),
  criticalDomains.map((domain) => (
    <link key={domain} rel="preconnect" href={`https://${domain}`} />
  ))
];
