/* eslint-disable global-require */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import get from "lodash.get";
import { NoSuchKey } from "@aws-sdk/client-s3";
import {
  createContentCache,
  SiteRoot,
  getDataFromTree,
  EditableProperty,
  EditableArt
} from "./assembler-render";
import siteProperties from "../properties/index.json";
import { apiUrl, gitCommitHash } from "./assmblrEnv";
import {
  fetchSnapshot,
  getContentCacheArgs,
  handlePopState,
  return404,
  return500
} from "./utils";
import { Head as AssemblerHead } from "../components/output-types/head";
import { getComponentMap } from "./rootConfig";
import { hasLocalStorage } from "../components/utilities/hasLocalStorage";
import { external } from "../prism.config";
import getEnv from "../components/utilities/env";

import { fetchPageMetaData } from "../properties/page-metadata";
import { getLoginDetails } from "../components/utilities/login-details";
import {
  metricContentCacheBackfilledPcsRequests,
  metricContentCacheFailedPcsRequests
} from "./constants";
import forYou from "~/content/sources/foryou-flex-headlines";
import { AssemblerContext } from "~/shared-components/AssemblerContext";
import { DEBUG_DATADOG } from "~/next-fusion-compat/fusion-modules/environment";
import logger from "./logger";

function createServerSideCache(slug, disableLazyLoading) {
  return createContentCache(
    getContentCacheArgs(
      `/render/${disableLazyLoading ? "ezproxy/" : ""}${slug}`
    )
  );
}

const getGetMetaValue = (meta) => {
  return (key) => {
    if (!meta) {
      // this just needs to be something safe.  This shouldn't happen but if it does return something.
      return "homepage";
    }
    return meta[key] || "undefined";
  };
};

async function getInflatedCache(ctx, originalProps) {
  const { query, AppTree } = ctx;
  // TODO: Extract into client side library
  const slug = query?.slug || "homepage";
  const snapshot = await fetchSnapshot(slug);
  const { rendering, timestamp } = snapshot;
  if (!rendering) {
    return null;
  }
  const rootCustomFields = get(rendering, "root.props.customFields", {});
  const section = get(rootCustomFields, "siteServiceSection", "");
  const metaData = await fetchPageMetaData(section);

  const cache = createServerSideCache(slug, originalProps.disableLazyLoading);
  await getDataFromTree(
    <AppTree
      pageProps={{
        ...originalProps,
        tree: rendering,
        timestamp,
        contentCache: cache,
        outputType: query.outputType || "default",
        slug
      }}
    />,
    cache
  );
  const cacheExtract = cache.extract();
  return {
    contentState: cacheExtract,
    tree: rendering,
    slug,
    metaData,
    globalContent: { ...rootCustomFields }
  };
}

const httpMiddleWareHandler =
  typeof window === "undefined"
    ? require("../http-middleware").default()
    : null;

const env = getEnv();
let hsClient;
if (typeof window === "undefined") {
  const createHotShotsClient =
    require("../http-middleware/hotshots-client").default;
  const buildId = gitCommitHash || "";
  hsClient = createHotShotsClient({
    buildId,
    environment: env
  });
}

async function getInitialFusionProps(ctx, originalProps) {
  // we need to keep track of how many times we're falling
  // back to stale content (failed PCS requests)
  let failedRequests = 0;
  let backfilledRequests = 0;

  // Set the AWS Region.
  const REGION = "us-east-1";
  let awsSdkHandler;
  let s3Client;

  const CONTENT_CACHE_BUCKET = "assembler-content-cache";
  const key = ctx.query.slug;

  const isVercel = process.env.VERCEL_ENV;

  // DON'T load these modules unless we're server side
  if (typeof window === "undefined") {
    // eslint-disable-next-line global-require
    awsSdkHandler = require("@aws-sdk/client-s3");
    // Create an Amazon S3 service client object.
    s3Client = new awsSdkHandler.S3Client({ region: REGION });
  }

  try {
    let json;
    let existingCache;
    let body;
    if (awsSdkHandler && !isVercel) {
      try {
        const render = await s3Client.send(
          new awsSdkHandler.GetObjectCommand({
            Bucket: CONTENT_CACHE_BUCKET,
            Key: `${env}/${key}`
          })
        );
        body = render?.Body;
      } catch (e) {
        if (e instanceof NoSuchKey) {
          logger.debug(`No cache with key ${env}/${key} in S3.`);
        } else {
          logger.error(`Failed to fetch ${env}/${key} cache from S3`, {
            error: e
          });
        }
        // DO NOT throw this error. Let the page try to render.
      }

      if (body) {
        json = await new Response(body).json().catch((e) => {
          logger.error(`Error in json body`, { error: e });
        });
        existingCache = json.cache;
        logger.info(`Successfully fetched ${env}/${key} content cache from S3`);
      }
    }

    const cacheData = await getInflatedCache(ctx, originalProps);
    if (!cacheData) {
      return return404(ctx.res, "Document not found");
    }
    // populate values from new cache with static cache data
    if (
      existingCache &&
      Object.values(existingCache).length &&
      cacheData?.contentState &&
      Object.values(cacheData.contentState).length
    ) {
      Object.keys(cacheData.contentState).forEach((k) => {
        if (!cacheData.contentState[k]) {
          // the content value could be:
          // - truthy
          // - "null" meaning a failure happened
          // - "undefined" meaning no fetch happened yet
          // we want to replace falsey values with non-undefined values
          // and take care that "null" values aren't overwritten with "undefined"
          failedRequests += 1;
          if (typeof existingCache[k] !== "undefined") {
            cacheData.contentState[k] = existingCache[k];
            backfilledRequests += 1;
          }
        }
      });
    }

    // Send metrics
    if (hsClient) {
      const totalRequests = existingCache
        ? Object.keys(existingCache).length
        : 0;
      const percentFailedRequests = (
        (100 * failedRequests) /
        totalRequests
      ).toFixed(2);
      const percentBackfilledRequests = (
        (100 * backfilledRequests) /
        totalRequests
      ).toFixed(2);

      hsClient.gauge(
        metricContentCacheFailedPcsRequests,
        percentFailedRequests,
        { slug: key, route: ctx?.req?.route }
      );
      hsClient.gauge(
        metricContentCacheBackfilledPcsRequests,
        percentBackfilledRequests,
        { slug: key, route: ctx?.req?.route }
      );
      if (DEBUG_DATADOG) {
        logger.info("STATSD", {
          route: ctx?.req?.url,
          mockBuffer: hsClient.mockBuffer
        });
      }
    }

    if (!body || !json || isVercel) {
      logger.info(`Creating a new content cache for ${env}/${key}`);
    }

    return cacheData;
  } catch (error) {
    return {
      error,
      slug: ctx.query.slug,
      ...ctx
    };
  }
}

const hasLS = hasLocalStorage();
export function withNextFusionCompat(initialprops, disableLazyLoading) {
  // NOTE: This is the actual component
  const WithNexFusionCompat = (props) => {
    const router = useRouter();

    /**
     * Begin rant
     *
     * With NextJS 9.5.5, we need to keep tree & contentState in state at the root
     * level due to some native "optimizations" that safari makes when users navigate
     * backwards. Safari on 'back' triggers our getInitialProps function on the CLIENT,
     * not the server, and we have a clause in getInitialFusionProps to return {} if not
     * on the server
     *
     * This was causing a 'white screen' on safari because the rendering object was not
     * being retained and was not being fetched again. Basically anything not kept in
     * state with this configuration is not re-generated on 'back'.
     */

    const [tree] = React.useState(props.tree);
    const [contentState] = React.useState(props.contentState || {});
    const [metaData] = React.useState(props.metaData || {});
    /* End rant */

    const referrer = `/render/${disableLazyLoading ? "ezproxy/" : ""}${
      props.slug
    }`;

    const componentMap = React.useMemo(() => getComponentMap(true), []);

    useEffect(() => {
      router.beforePopState(handlePopState);
      // Only run this once, so:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contentCache = React.useMemo(() => {
      const args = getContentCacheArgs(
        referrer,
        contentState,
        `${external}/api`
      );
      return props.contentCache || createContentCache(args);
    }, [contentState, props.contentCache, referrer]);

    // we're passed isMobile=0 or isMobile=1, want to return a bool
    const rawIsMobile = Number(router?.query?.isMobile || 0) === 1;

    let requestUri = router.asPath;
    let isMobile = props.isMobile || rawIsMobile;
    let isNoNav = props.isNoNav;
    let variant = props.variant;
    if (typeof window !== "undefined") {
      requestUri = `${router.asPath.replace(/\?.+/, "")}?variant=${
        window.assemblerVariant
      }`;
      isMobile = window.isMobile;
      isNoNav = window.isNoNav;
      variant = window.assemblerVariant;
    }

    // WFO-6336 WFO-6350
    // Desktop Safari 16.4 and higher has mismatched or corrupted images
    // As of 2023-05-19
    const { userAgent } = getLoginDetails();
    const isDesktopSafari164OrHigher =
      !isMobile &&
      userAgent?.includes("Safari") &&
      /Version\/16.([4-9]|\d{2,})/.test(userAgent);

    const additionalContext = {
      metaValue: getGetMetaValue(metaData),
      siteProperties: {
        ...siteProperties,
        disableLazyLoading:
          disableLazyLoading || isDesktopSafari164OrHigher || false
      },
      apiUrl,
      globalContent: props.globalContent || {}
    };

    return (
      <AssemblerContext.Provider
        value={{
          tree,
          EditableProperty,
          EditableArt,
          isMobile,
          isNoNav,
          variant,
          hasLocalStorage: hasLS,
          lastPublishedTimestamp:
            (tree && tree.lastPublishedActualTimestamp) || new Date().getTime(),
          // NOTE: Put forYou in context to avoid circular dependency
          forYou,
          metaData
        }}
      >
        <AssemblerHead
          metaValue={getGetMetaValue(metaData)}
          variant={variant}
          siteProperties={siteProperties}
          renderLibraries={() => null}
          isMobile={isMobile}
          isNoNav={isNoNav}
          globalContent={props.globalContent}
        />
        <SiteRoot
          key={tree && tree.lastPublishedActualTimestamp}
          {...props}
          tree={tree}
          additionalContext={additionalContext}
          contentCache={contentCache}
          components={componentMap}
          requestUri={requestUri}
        ></SiteRoot>
      </AssemblerContext.Provider>
    );
  };

  WithNexFusionCompat.propTypes = {
    tree: PropTypes.object,
    contentCache: PropTypes.object,
    contentState: PropTypes.object,
    metaData: PropTypes.object,
    globalContent: PropTypes.object,
    slug: PropTypes.string,
    variant: PropTypes.string,
    isMobile: PropTypes.bool,
    isNoNav: PropTypes.bool
  };

  WithNexFusionCompat.getInitialProps = async (ctx) => {
    const originalPageProps = { disableLazyLoading };
    try {
      const { query, res, req } = ctx;

      if (httpMiddleWareHandler) {
        req.route = "/render/[slug]";
        req.query = query;
        await httpMiddleWareHandler.apply(req, res);
      }

      if (typeof window !== "undefined") {
        return {};
      }

      const outputType = query.outputType || "default";

      const fusionProps = await getInitialFusionProps(ctx, originalPageProps);
      if (fusionProps.error) {
        if (ctx.res) {
          if (fusionProps.error.Code === "NoSuchKey")
            return404(ctx.res, fusionProps.error);
          return return500(ctx.res, fusionProps.error);
        }
        return {
          ...originalPageProps,
          error: fusionProps.error.toString
            ? fusionProps.error.toString()
            : fusionProps.error.message
        };
      }

      const slug = ctx && ctx.query && ctx.query.slug;

      // we're passed isMobile=0 or isMobile=1, want to return a bool
      const rawIsMobile = (ctx && ctx.query && ctx.query.isMobile) || 0;
      const variant = ctx?.query?.variant;
      const isMobile = Number(rawIsMobile) === 1;

      // we're passed no_nav=true sometimes
      const isNoNav = ctx?.query?.no_nav === "true";

      const fusionPropsWithHead = {
        ...fusionProps,
        contentState: {
          ...fusionProps.contentState
        },
        requestUri: `/${slug}`,
        outputType,
        isMobile,
        isNoNav,
        variant
      };

      ctx.pageProps = fusionPropsWithHead;

      return {
        ...originalPageProps,
        ...fusionPropsWithHead,
        outputType
      };
    } catch (e) {
      if (ctx.res) {
        ctx.res.statusCode = 500;
        ctx.res.end(JSON.stringify({ error: 500 }));
      }
      return {
        ...originalPageProps,
        error: e.toString ? e.toString() : e.message
      };
    }
  };
  return WithNexFusionCompat;
}
