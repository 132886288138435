import { SubsConfig } from "@washingtonpost/subs-hooks";
import {
  getTaxonomyTags,
  getSubType,
  getSubscriberContentTags
} from "../../utilities/ans-helper";
import getEnv from "../../utilities/env";

/**
 * Generate the subscriptions configuration json/script tag
 *
 * @returns {object} the subscriptions configuration
 */
export const getSubsConfig = ({
  canonicalUrl,
  commercialNode,
  contentType,
  globalContent: content,
  isAdmin,
  isHomepage,
  section,
  siteDomainURL
}) => {
  const contentTags = getTaxonomyTags(content);
  const subscriberContentTags = getSubscriberContentTags(content);
  const subType = getSubType(content);

  return SubsConfig({
    canonicalUrl,
    contentCode: subscriberContentTags,
    contentCommercialNode: commercialNode,
    contentSection: section,
    contentSubtype: subType,
    contentTags,
    contentType,
    domain: siteDomainURL,
    env: getEnv(),
    isAdmin,
    isHomepage
  });
};
