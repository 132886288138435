import getConfig from "next/config";

const {
  publicRuntimeConfig: {
    SERVER_SIDE_API_DOMAIN,
    CLIENT_SIDE_API_DOMAIN,
    CONTENT_SOURCE
  },
  serverRuntimeConfig
} = getConfig();

export const serverSideApiUrl = `${SERVER_SIDE_API_DOMAIN}/api`;
export const clientSideApiUrl = `${CLIENT_SIDE_API_DOMAIN}/api`;
export const contentSource = `${CONTENT_SOURCE}`;
export const fusionApiHeaders = serverRuntimeConfig.fusionApiHeaders;

export const metricContentCacheFailedPcsRequests =
  "content_cache_failed_pcs_requests";
export const metricContentCacheBackfilledPcsRequests =
  "content_cache_backfilled_pcs_requests";
