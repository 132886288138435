import getConfig from "next/config";

const {
  publicRuntimeConfig: { environment, buildId, DEBUG_DATADOG }
} = getConfig();

// TODO: Need add sandbox environment here?
const ENVIRONMENT = environment;
const BUILD_ID = buildId;

export { BUILD_ID, ENVIRONMENT, DEBUG_DATADOG };

// TODO: Add the rest of the env vars here i.e. prismKey, etc
