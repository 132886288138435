import React from "react";

/* eslint-disable max-len */
/**
 * Ad Revenue Science team is building an API that will determine the ad impression
 * value of  an article using various information such as Zeus key-values, user's
 * geo location, and ad unit path. Tetro will make a request to the API with these
 * information in the request body and receive ad value in the response which will
 * be integrated with the Tetro metering engine to influence paywall decisions.
 * Some of the common key values that now need to be shared by Tetro and Zeus have
 * been moved to a separate/isolated component.
 *
 * Outline of the decision here:
 * https://arcpublishing.atlassian.net/wiki/spaces/WPDP/pages/3207627248/Extracting+Common+Zeus+Key+Values+for+Paywall+and+Zeus+Decision-making
 * @returns script tag loading the common key-values to be shared by Tetro and Zeus
 */
function ZeusCommonKeyValues() {
  const zeusCommonKeyValuesPath =
    "https://www.washingtonpost.com/wp-stat/zeus-common-key-values/dist/main.js";
  return (
    <React.Fragment>
      <script
        data-testid="zeus-common-key-values"
        key="zeus-common-key-values"
        src={zeusCommonKeyValuesPath}
        async
      />
    </React.Fragment>
  );
}
export default ZeusCommonKeyValues;

export function loadZeusCommonKeyValues() {
  const zeusCommonKeyValuesPath =
    "https://www.washingtonpost.com/wp-stat/zeus-common-key-values/dist/main.js";
  return [
    <script
      data-testid="zeus-common-key-values"
      key="zeus-common-key-values"
      src={zeusCommonKeyValuesPath}
      async
    />
  ];
}
