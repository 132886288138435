/**
 * NewsMediaOrg SEO Schema
 * @returns {Object}
 */
export const newsMediaOrgSchema = {
  "@context": "https://schema.org",
  "@type": "NewsMediaOrganization",
  name: "The Washington Post",
  url: "https://www.washingtonpost.com/",
  logo: "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/IXXV6UT42RHNTA2LQL5CVZH7NQ.png",
  correctionsPolicy: "https://www.washingtonpost.com/policies-and-standards/",
  diversityPolicy: "https://www.washingtonpost.com/policies-and-standards/",
  ethicsPolicy: "https://www.washingtonpost.com/policies-and-standards/",
  masthead: "https://www.washingtonpost.com/policies-and-standards/masthead/",
  unnamedSourcesPolicy:
    "https://www.washingtonpost.com/policies-and-standards/",
  verificationFactCheckingPolicy:
    "https://www.washingtonpost.com/policies-and-standards/",
  foundingDate: "1877-12-06",
  sameAs: [
    "https://www.facebook.com/washingtonpost/",
    "https://x.com/washingtonpost",
    "https://www.instagram.com/washingtonpost/",
    "https://www.youtube.com/@WashingtonPost",
    "https://www.linkedin.com/company/washingtonpost",
    "https://en.wikipedia.org/wiki/The_Washington_Post"
  ]
};
