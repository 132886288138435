export const getFrontPageName = ({
  pageName = "",
  isFront = false,
  isJsonApp = false
}) => {
  if (pageName) {
    const formatPageName = () => {
      if (pageName.includes("/")) {
        return pageName.slice(1, pageName.length - 1).replace(/\//, " - ");
      }
      return pageName;
    };

    const formatJsonAppPrefix = () =>
      pageName.includes("front -") || !isFront ? "" : "front - ";

    return isJsonApp
      ? `${formatJsonAppPrefix()}${formatPageName()}`
      : formatPageName();
  }
  return null;
};
