import React from "react";
import PropTypes from "@arc-fusion/prop-types";

const FrontsAdConfig = ({
  contentType,
  commercialNode,
  keywords,
  canonicalUrl
}) => {
  const generateAdConfig = () => {
    const isHomepage = contentType === "homepage";

    const PLATFORM_NAME = "assembler";

    const permutive = {
      platform: PLATFORM_NAME,
      canonicalUrl

      // TODO more metadata will be added in a future PR
      // section front behavior should be aligned with Spectrum
      /* eslint-disable max-len */
      // https://github.com/WPMedia/spectrum/blob/7d65e43565c92638423ee19d701f61221e46cfab/components/document-elements/shared/ad-config.jsx#L222-L234
    };

    return {
      permutive,
      commercialNode,
      contentType,
      keywords,
      isHomepage
    };
  };

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.wpAdFusion = ${JSON.stringify(generateAdConfig())}`
      }}
    />
  );
};

FrontsAdConfig.propTypes = {
  contentType: PropTypes.oneOf(["homepage", "front"]),
  commercialNode: PropTypes.string,
  keywords: PropTypes.array,
  canonicalUrl: PropTypes.string
};

export default FrontsAdConfig;

export const getFrontsAdConfig = ({
  contentType,
  commercialNode,
  keywords,
  canonicalUrl
}) => {
  const generateAdConfig = () => {
    const isHomepage = contentType === "homepage";

    const PLATFORM_NAME = "assembler";

    const permutive = {
      platform: PLATFORM_NAME,
      canonicalUrl

      // TODO more metadata will be added in a future PR
      // section front behavior should be aligned with Spectrum
      /* eslint-disable max-len */
      // https://github.com/WPMedia/spectrum/blob/7d65e43565c92638423ee19d701f61221e46cfab/components/document-elements/shared/ad-config.jsx#L222-L234
    };

    return {
      permutive,
      commercialNode,
      contentType,
      keywords,
      isHomepage
    };
  };

  return [
    <script
      dangerouslySetInnerHTML={{
        __html: `window.wpAdFusion = ${JSON.stringify(generateAdConfig())}`
      }}
      key={`${contentType}-wpAdFusion`}
      data-testid={`${contentType}-wpAdFusion`}
    />
  ];
};
